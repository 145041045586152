import {Component, OnInit} from '@angular/core';
import { ContactsService } from 'src/app/services/contacts.service';
import { DeviceDetectorService } from 'src/app/services/device-detector.service';
import { firstValueFrom } from 'rxjs';

@Component(
  {
    selector: 'app-error', 
    templateUrl: './error.component.html', 
    styleUrls: ['./error.component.scss']
  }
  )
export class ErrorComponent implements OnInit {

  contacts:any;
  isMobile=this.deviceDetector.isMobile();

    constructor(
      private contactService: ContactsService,
      private deviceDetector: DeviceDetectorService) {
        document.body.style.backgroundColor = '#FFEDE7';
    }


    async ngOnInit() {
      console.log(this.isMobile);
      this.contacts = await firstValueFrom(this.contactService.getContacts());
      console.log(this.contacts);

      // const events:any = []; 
 
      // Object.keys(window).forEach(key => { 
      //   if (/^on/.test(key)) { 
      //     events.push(key.slice(2)); 
      //     window.addEventListener(key.slice(2), () => { 
      //       console.log(`${key.slice(2)} event occurred`); 
      //     }); 
      //   } 
      // }); 

       
    }

    close() {
        window.self.close();
    }

    openMyApp() { 
        window.location.href = 'https://moneda.vilawatt.cat/mobile-link/contact';
    }





}

