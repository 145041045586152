<div class="container">
  <div
  class="row align-items-center"
  style="min-height: 100vh">
  <div id="video">
    <!-- <button (click)="start()">PROVA</button> -->
  </div>
</div>
</div>


<ngx-loading
[show]="loading"
[config]="{ backdropBorderRadius: '3px' }"
></ngx-loading>
