// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

declare var require: any;

export const environment = {
  USERAGENT: require( '../../package.json').useragent,
  VERSION: require( '../../package.json').version,
  NAME: "dev",
  API_ENDPOINT: "https://test-vilawatt.ubiquat.com/mobile-api",
  //API_ENDPOINT: "https://moneda.vilawatt.cat/mobile-api",
  //API_ENDPOINT: "https://192.168.2.52:8091/mobile-api",
  //API_ENDPOINT: "http://192.168.2.52:8080",
  DEBUG: true,
  production: false,
  MAX_COUNTERS:{
    CONNECTIONINTERRUPTED:50
  }
};

// https://localhost:4200/video-id?token=Npogx9-jk67DD3FXKrOBcrBRcQ15hmpIj8ck1gLIdmuZvgCB7MRQkSnbO1_me3MvKa3Czs_lHzum6R5oGsg5oohXB2Igm6FLVfg-z2UjmnA=&random=ab462d48-c06c-4268-9c32-891d7c25c114&user=-5069781999569216775

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
