import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
declare const EID: any;
import { Router } from '@angular/router';
import { VideoidService } from 'src/app/services/videoid.service';
import { environment as ENV } from 'src/environments/environment';



@Component({
  selector: 'app-videoid',
  templateUrl: './videoid.component.html',
  styleUrls: ['./videoid.component.scss']
})
export class VideoidComponent{
  @ViewChild('spinner') spinner: ElementRef | undefined;

  requirements:any;
  videoId :any = EID.videoId('#video', {lang: "es"})
  public loading = false;


  data:any={
    request:{
      random:null,
      token:null,
      user:null,
    },

    videoId:null,
    resultOk:null,
    resultMessage: null,
    backError:null
  };

  counters={
    connectionInterrupted:0
  };


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private videoService: VideoidService)
    {
      this.route.queryParams
      .subscribe(
        (params:any) => {
          console.log(params);
          if(Object.keys(params).length){
            this.data.request.random=params.random;
            this.data.request.token = params.token;
            this.data.request.user = params.user;
            this.loading = true;
            this.start();
            console.log(this.data);
          }else{
            this.router.navigate(['/tabs']);
          }
      });
    }

  start() {
    this.loading = false;
    this.videoId.start({ authorization: this.data.request.token });

    this.videoId.on("phaseStarting", (phase: any) => { this.handleStarting(phase); });

    this.videoId.on("completed", (video: any) => { this.handleCompleted(video); } );

    this.videoId.on("failed",    (error: any) => { this.handleFailed(error);} );

    this.videoId.on("notification", (event: any) => { this.handleNotification(event); } );

    this.videoId.on("phaseFinished", (phase: any) => { this.handlePhaseFinished(phase); });

  }

  handleStarting(phase:any){
    console.log('handleStarting');
    console.log(phase);
    phase.continue();
  }

  handleCompleted(video:any){
    console.log('handleCompleted');
    console.log(video);
    console.log(this.data);
    this.data.videoId=video.id;
    this.data.resultOk=true;
    this.data.resultMessage='';
    this.videoService.registerFinish(this.data)
    .subscribe(
      (response:any)=>{
        console.log(response);
        this.goToSuccess();
      },
      (error:any)=>{
        console.log(error);
        this.data.backerror=error;
        this.goToError(this.data);
      }
    )

  }

  handleNotification=(event:any)=>{
    console.log('handleNotification');
    console.log(event);
    console.log(this.counters);
    if (event.name === "Connection.Interrupted"){
      this.counters.connectionInterrupted++;
      if(this.counters.connectionInterrupted > ENV.MAX_COUNTERS.CONNECTIONINTERRUPTED ){
        this.data.resultOk=false;
        this.data.resultMessage=JSON.stringify(event);
        this.videoService.registerFinish(this.data).subscribe();
        this.goToError(this.data);
      }
    }

    if (event.name === "InactiveUser.Aborted"){
      this.data.resultOk=false;
      this.data.resultMessage=JSON.stringify(event);
      this.videoService.registerFinish(this.data).subscribe();
      this.goToError(this.data);
    }
  }

  handlePhaseFinished=(phase:any)=>{
    console.log('handlePhaseFinished');
    console.log(phase);
  }

  handleFailed=(error:any)=>{
    console.log('handleFailed');
    console.log(error);
    this.data.resultOk=false;
    this.data.resultMessage=error;
    this.videoService.registerFinish(this.data).subscribe();
    this.goToError(this.data);
  }


  goToSuccess(){
    this.router.navigate(['/success']);
  }

  goToError(data:any){
    const navigationExtras: NavigationExtras = {
      state: {
        data: data
      }
    };
    this.router.navigate(['/error'], navigationExtras);
  }
}
