<div class="container">
  <div
  class="row align-items-center"
  style="min-height: 100vh; text-align:center;">
  <div id="notfound" class="text-center ">

    <fa-icon [icon]="faCheck"></fa-icon>
    <h2>Procés finalitzat amb èxit</h2>
    <p>Només queda esperar a que es confirmi la valildació del seu compte i llavors podrà gaudir dels avantatges de Vilawatt.</p>
    
    
    <div class="is-mobile" *ngIf="isMobile" >
      <button class="my-button" (click)="openMyApp()">Torna</button>
    </div>
    
  </div>
</div>
</div>
