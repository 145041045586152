import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VideoidService {
  apiUrl=this.commonService.getApiUrl();

  constructor(
    private commonService : CommonService,
    private http: HttpClient)
    { }


  registerFinish(data:any){
    return this.http.post(
      `${this.apiUrl}/members/register/${data.request.user}/video-id/finish`,
      {
        'random': data.request.random,
        'videoId': data.videoId,
        'resultOk': data.resultOk,
        'errorMsg': data.resultMessage
      }
      );
  }



}
