import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { HttpClient, HttpParams } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ContactsService {
  apiUrl=this.commonService.getApiUrl();

  constructor(   
    private commonService : CommonService,
    private http: HttpClient) 
  { }

  getContacts(){
    return this.http.get(`${this.apiUrl}/misc/contact-info`);
  }
}
