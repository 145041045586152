import { environment } from './../environments/environment';
import { Component, OnInit, DoCheck, AfterViewChecked, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { get } from 'scriptjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss',]
})
export class AppComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal) {


  }
  ngOnInit() {
  }

}
