import { Component, OnInit } from '@angular/core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { DeviceDetectorService } from 'src/app/services/device-detector.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  faCheck = faCheck;
  isMobile=this.deviceDetector.isMobile();

  constructor(private deviceDetector: DeviceDetectorService ) {
    document.body.style.backgroundColor='#E4F2DE';
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if(this.deviceDetector.isMobile()){
      window.location.href = 'https://moneda.vilawatt.cat/mobile-link/contact';
    }
  }

      openMyApp() { 
        window.location.href = 'https://moneda.vilawatt.cat/mobile-link/contact';
    }

}
