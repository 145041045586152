import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from 'src/app/pages/error/error.component';
import { VideoidComponent } from 'src/app/pages/videoid/videoid.component';
import { SuccessComponent } from 'src/app/pages/success/success.component';

const routes: Routes = [
  { path: 'video-id', component: VideoidComponent },
  { path: '', component: VideoidComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'success', component: SuccessComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
