<div class="container">
  <div
  class="row align-items-center"
  style="min-height: 100vh; text-align:center;">
  <div id="notfound" class="text-center ">
    <h1>😮</h1>
    <h2>Oops! Alguna cosa ha anat malament</h2>
    <p>Si us plau poseu-vos en contacte amb l'administrador</p>

    <ul *ngIf="contacts">
      <li><b>Telèfon:</b> {{contacts.phoneNumber}}</li>
      <li><b>E-mail:</b> {{contacts.email}}</li>
    </ul>
    <div class="is-mobile" *ngIf="isMobile" >
      <button class="my-button" (click)="openMyApp()">Torna</button>
    </div>

  </div>
</div>
</div>
