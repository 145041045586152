import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeviceDetectorService {

  constructor() { }

  public isMobile(): boolean {
    const userAgent = navigator.userAgent.toLowerCase();
    console.log(userAgent);
    return userAgent.includes('mobile');
  }
}
