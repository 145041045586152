import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  

  constructor() { }


  public getApiUrl(path?:string) {
    if (path) {
      return environment.API_ENDPOINT + '/' + path;
    }
    return environment.API_ENDPOINT;
  }

}
